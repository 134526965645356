/**
 * Client side behavior for tooltips
 */

import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";
import { generateRandomString } from "../lib/util";

export default class extends Controller {
  connect() {
    setTimeout(() => {
      const tipElementToMove = this.element.querySelector(
        "[data-is-tooltip='true']"
      );

      this.uniqueTooltipId = generateRandomString(24);
      this.element.setAttribute("data-tooltip-gen-id", this.uniqueTooltipId);

      tipElementToMove.setAttribute(
        "data-tooltip-gen-id",
        this.uniqueTooltipId
      );

      tipElementToMove.remove();
      this.tooltipRoot.append(tipElementToMove);
    }, 100);
  }

  enter() {
    if (this.tipElement) {
      // make sure tip element has proper positioning
      const parentBoundingRect = this.element.getBoundingClientRect();
      this.tipElement.style.top = `${
        parentBoundingRect.top + window.scrollY
      }px`;
      this.tipElement.style.left = `${parentBoundingRect.left}px`;

      enter(this.tipElement);
    }
  }

  leave() {
    if (this.tipElement) {
      leave(this.tipElement);
    }
  }

  get tipElement() {
    return this.tooltipRoot.querySelector(
      `[data-is-tooltip='true'][data-tooltip-gen-id='${this.tooltipGenId}']`
    );
  }

  get tooltipGenId() {
    return this.element.getAttribute("data-tooltip-gen-id");
  }

  get tooltipRoot() {
    return document.querySelector("#tooltip-root-container");
  }
}
