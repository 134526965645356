import { Controller } from "@hotwired/stimulus";
import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import TextStyle from "@tiptap/extension-text-style";
import Link from "@tiptap/extension-link";

import DelMark from "../lib/tiptap/marks/del_mark";
import { setLink } from "../lib/tiptap/extensions/link";
import { uploadImage } from "../lib/tiptap/extensions/image_upload";

export default class extends Controller {
  static targets = [
    "editor",
    "input",
    "boldButton",
    "italicButton",
    "strikeButton",
    "linkButton",
    "linkDropdown",
    "linkInput",
    "setLinkButton",
    "unsetLinkButton",
    "heading1Button",
    "heading2Button",
    "heading3Button",
    "blockquoteButton",
    "codeButton",
    "bulletListButton",
    "orderedListButton",
    "uploadImageButton",
    "uploadContainer",
    "progressBar",
    "uploadPercentage",
    "fileSize"
  ];

  static values = {
    uploadMaxSizeMb: Number,
    uploadAllowedMimeTypes: String,
    uploadAllowedMimeTypesHumanized: String,
  };

  connect() {
    this.uploadMaxSizeMbValue = this.element.dataset.uploadMaxSizeMb;
    this.uploadAllowedMimeTypesValue = this.element.dataset.uploadAllowedMimeTypes;
    this.uploadAllowedMimeTypesHumanizedValue = this.element.dataset.uploadAllowedMimeTypesHumanized;

    this.editor = new Editor({
      element: this.editorTarget,
      extensions: [
        StarterKit.configure({
          strike: false,
          hardBreak: false,
          heading: {
            levels: [1, 2, 3],
          },
        }),
        TextStyle,
        DelMark,
        Image,
        Link.configure({
          openOnClick: false,
          autolink: true,
          linkOnPaste: true,
          defaultProtocol: "https",
          protocols: ["http", "https"],
        }),
      ],
      content: this.inputTarget.value,
      onUpdate: () => {
        this.inputTarget.value = this.editor.getHTML();
        this.updateToolbarState();
      },
      onSelectionUpdate: () => {
        this.updateToolbarState();
      },
    });
  }

  toggleBold() {
    this.editor.chain().focus().toggleBold().run();
  }

  toggleItalic() {
    this.editor.chain().focus().toggleItalic().run();
  }

  toggleStrike() {
    this.editor.chain().focus().toggleDel().run();
  }

  toggleHeading1() {
    this.editor.chain().focus().toggleHeading({ level: 1 }).run();
  }

  toggleHeading2() {
    this.editor.chain().focus().toggleHeading({ level: 2 }).run();
  }

  toggleHeading3() {
    this.editor.chain().focus().toggleHeading({ level: 3 }).run();
  }

  toggleBlockquote() {
    this.editor.chain().focus().toggleBlockquote().run();
  }

  toggleCode() {
    this.editor.chain().focus().toggleCodeBlock().run();
  }

  toggleBulletList() {
    this.editor.chain().focus().toggleBulletList().run();
  }

  toggleOrderedList() {
    this.editor.chain().focus().toggleOrderedList().run();
  }

  toggleUndo() {
    this.editor.chain().focus().undo().run();
  }

  toggleRedo() {
    this.editor.chain().focus().redo().run();
  }

  toggleLinkDropdown() {
    this.linkDropdownTarget.classList.toggle("hidden");
  }

  handleEnterLinkKeydown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.setLink();
    }
  }

  setLink() {
    setLink(this.editor, this.linkInputTarget);
    this.toggleLinkDropdown();
  }

  unsetLink() {
    this.editor.chain().focus().unsetLink().run();
    this.toggleLinkDropdown();
  }

  uploadImage() {
    uploadImage(this.editor, this);
  }

  updateToolbarState() {
    const updateButtonState = (button, type, options = {}) => {
      if (!button) return;

      const isActive = type ? this.editor.isActive(type, options) : false;
      button.classList.toggle("is-active", isActive);
      button.disabled = this.shouldDisableButton(button);
    };

    if (this.boldButtonTarget) updateButtonState(this.boldButtonTarget, "bold");
    if (this.italicButtonTarget) updateButtonState(this.italicButtonTarget, "italic");
    if (this.strikeButtonTarget) updateButtonState(this.strikeButtonTarget, "del");
    if (this.linkButtonTarget) updateButtonState(this.linkButtonTarget, "link");
    if (this.heading1ButtonTarget) updateButtonState(this.heading1ButtonTarget, "heading", { level: 1 });
    if (this.heading2ButtonTarget) updateButtonState(this.heading2ButtonTarget, "heading", { level: 2 });
    if (this.heading3ButtonTarget) updateButtonState(this.heading3ButtonTarget, "heading", { level: 3 });
    if (this.blockquoteButtonTarget) updateButtonState(this.blockquoteButtonTarget, "blockquote");
    if (this.codeButtonTarget) updateButtonState(this.codeButtonTarget, "codeBlock");
    if (this.bulletListButtonTarget) updateButtonState(this.bulletListButtonTarget, "bulletList");
    if (this.orderedListButtonTarget) updateButtonState(this.orderedListButtonTarget, "orderedList");
    if (this.uploadImageButtonTarget) updateButtonState(this.uploadImageButtonTarget, null);

    // Set link input if link is active
    if (this.linkButtonTarget && this.editor.isActive("link")) {
      const href = this.editor.getAttributes("link").href;
      this.linkInputTarget.value = href;
    } else {
      this.linkInputTarget.value = '';
    }
  }

  shouldDisableButton(button) {
    if (!this.editor) return true;

    // Disable formatting buttons in a code block
    if (this.editor.isActive("codeBlock")) {
      return button !== this.codeButtonTarget;
    }

    // Disable buttons that don't belong inside a blockquote
    if (this.editor.isActive("blockquote") &&
        [this.codeButtonTarget, this.bulletListButtonTarget, this.orderedListButtonTarget, this.uploadImageButtonTarget].includes(button)) {
      return true;
    }

    // Disable certain buttons when inside bullet or numbered lists
    if ((this.editor.isActive("bulletList") || this.editor.isActive("orderedList")) &&
        [this.blockquoteButtonTarget, this.codeButtonTarget, this.uploadImageButtonTarget].includes(button)) {
      return true;
    }

    // Disable heading buttons inside lists
    if ((this.editor.isActive("bulletList") || this.editor.isActive("orderedList")) &&
        [this.heading1ButtonTarget, this.heading2ButtonTarget, this.heading3ButtonTarget].includes(button)) {
      return true;
    }

    // Disable list buttons inside headings
    if (this.editor.isActive("heading", { level: 1 }) ||
        this.editor.isActive("heading", { level: 2 }) ||
        this.editor.isActive("heading", { level: 3 })) {
      if ([this.bulletListButtonTarget, this.orderedListButtonTarget].includes(button)) {
        return true;
      }
    }

    // Disable link button unless text is selected or cursor is inside a link
    if (button === this.linkButtonTarget) {
      return this.editor.state.selection.empty && !this.editor.isActive("link");
    }

    return false;
  }

  disconnect() {
    this.editor.destroy();
  }
}
