// This module handles image uploads for use with the TipTap editor.

import { DirectUpload } from "@rails/activestorage";
import Flash from "../../flash";
import Files from "../../files";

export function uploadImage(editor, controller) {
  const { uploadMaxSizeMbValue, uploadAllowedMimeTypesValue, uploadAllowedMimeTypesHumanizedValue } = controller;

  const input = document.createElement("input");
  input.type = "file";
  input.accept = "image/*";
  
  input.addEventListener("change", (event) => {
    const file = event.target.files[0];
    if (file) {
      const { success, message } = Files.verifyAttachment(file, {
        fileTypes: uploadAllowedMimeTypesValue,
        fileTypesHumanized: uploadAllowedMimeTypesHumanizedValue,
        maxSizeMb: uploadMaxSizeMbValue,
      });

      if (!success) {
        Flash.addFlash(message, "error");
        return;
      }

      handleDirectUpload(file, editor, controller);
    }
  });

  input.click();
}

function handleDirectUpload(file, editor, controller) {
  // Define upload container targets
  const uploadContainer = controller.uploadContainerTarget;
  const progressBar = controller.progressBarTarget;
  const uploadPercentage = controller.uploadPercentageTarget;
  const fileSize = controller.fileSizeTarget;

  // Show upload container
  uploadContainer.style.display = "block";

  const upload = new DirectUpload(file, "/rails/active_storage/direct_uploads", {
    directUploadWillStoreFileWithXHR(request) {
      request.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          // Calculate progress
          const progress = (event.loaded / event.total) * 100;
          const sizeInMb = (file.size / (1024 * 1024)).toFixed(2);

          // Update progress container
          progressBar.style.width = `${progress}%`;
          uploadPercentage.textContent = `${Math.round(progress)}%`;
          fileSize.textContent = `${sizeInMb} MB`;
        }
      });
    },
  });

  upload.create((error, blob) => {
    if (error) {
      // Reset and hide container on error
      uploadContainer.style.display = "none";
      progressBar.style.width = "0%";
      uploadPercentage.textContent = "0%";
      fileSize.textContent = "0 MB";

      // Add flash message notifying of error
      Flash.addFlash("Error uploading image. Please try again.", "error");
    } else {
      // Successfully uploaded to storage, insert image into the editor
      const imageUrl = `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
      insertImage(imageUrl, editor);

      // Reset and hide container on success
      setTimeout(() => {
        uploadContainer.style.display = "none";
        progressBar.style.width = "0%";
        uploadPercentage.textContent = "0%";
        fileSize.textContent = "0 MB";
      }, 1500);
    }
  });
}

function insertImage(imageUrl, editor) {
  if (editor) {
    editor.chain().focus().setImage({ src: imageUrl }).createParagraphNear().run();
  } else {
    console.error("Editor instance is not defined.");
  }
}
