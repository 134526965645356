import Api from "./index";

export type GetChildrenResponse = {
  success: boolean;
  data?: { html: string };
};

export async function getChildren(params: {
  hexId: string;
  level: number;
  context?: string;
  parentIsSelected?: boolean;
  filterStatus?: string;
  treeBehavior: object;
}): Promise<GetChildrenResponse> {
  const {
    hexId,
    level = 0,
    context,
    parentIsSelected = false,
    filterStatus = null,
    treeBehavior = null,
  } = params;

  const urlSearchParams = new URLSearchParams("");
  urlSearchParams.set("level", level.toString());

  if (context) {
    urlSearchParams.set("context", context);
  }

  if (parentIsSelected) {
    urlSearchParams.set(
      "parent_is_selected",
      parentIsSelected ? "true" : "false"
    );
  }

  if (filterStatus) {
    urlSearchParams.set("filter_status", filterStatus);
  }

  if (treeBehavior) {
    urlSearchParams.set("tree_behavior", treeBehavior.serialize());
  }

  const url = `${Api.baseUrl()}/treeables/${hexId}/children?${urlSearchParams.toString()}`;

  const res = await fetch(url, {
    method: "GET",
    headers: { Accept: "text/vnd.turbo-stream.html" },
  });

  if (res.ok) {
    const data = await res.text();
    return { success: true, data: { html: data } };
  } else {
    return { success: false };
  }
}
