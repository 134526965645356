import { Controller } from "@hotwired/stimulus";
import Api from "../lib/api";
import Flash from "../lib/flash";

class SingleSelectBehavior {
  constructor({ tree, data }) {
    this.tree = tree;
    this.data = data;
    this.turboFrame = data.turboFrame;
    this.getChildrenApiRequestParams = data.getChildrenApiRequestParams;
  }

  toggleNode(nodeRootElement) {
    if (this.tree.isNodeSelected(nodeRootElement)) {
      this.tree.unselectNode(nodeRootElement);
    } else {
      this.tree.clearSelection();
      this.tree.selectNode(nodeRootElement, {
        selectChildren: false,
        handleParents: false,
      });
    }
  }

  injectToggleNodeParams(params) {
    params.filterStatus = this.getChildrenApiRequestFilterStatusParam;
    params.treeBehavior = this;
  }

  serialize() {
    return JSON.stringify(this.data);
  }

  get getChildrenApiRequestFilterStatusParam() {
    return this.getChildrenApiRequestParams?.filterStatus;
  }

  get getChildrenApiRequestPassSelection() {
    return false;
  }
}

class MultiSelectBehavior {
  constructor({ tree, data }) {
    this.tree = tree;
    this.data = data;
    this.turboFrame = data.turboFrame;
  }

  toggleNode(nodeRootElement) {
    if (this.tree.isNodeSelected(nodeRootElement)) {
      this.tree.unselectNode(nodeRootElement);
    } else {
      this.tree.selectNode(nodeRootElement);
    }
  }

  injectToggleNodeParams(params) {
    params.filterStatus = null;
    params.treeBehavior = this;
  }

  serialize() {
    return JSON.stringify(this.data);
  }

  get getChildrenApiRequestPassSelection() {
    return true;
  }
}

function behaviorFromValue(tree, data) {
  if (data.name == "single_select") {
    return new SingleSelectBehavior({
      tree,
      data,
    });
  } else if (data.name == "multi_select") {
    return new MultiSelectBehavior({
      tree,
      data,
    });
  }
}

export default class extends Controller {
  static targets = [
    "node",
    "actionPanel",
    "valuesFormField",
    "expandedValuesFormField",
    "resetSelection",
  ];

  static values = {
    behavior: Object,
  };

  /**
   * life cycle
   */

  connect() {
    this.observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "aria-expanded"
        ) {
          const toggleButton = mutation.target;
          const treeNodeRoot = this.getTreeNodeRootForElement(toggleButton);
          const isExpanded =
            toggleButton.getAttribute("aria-expanded") == "true";

          if (isExpanded) {
            this.addNodeValueToExpandedList(treeNodeRoot);
          } else {
            this.removeNodeValueFromExpandedList(treeNodeRoot);
          }
        }
      });
    });

    this.observer.observe(this.element, {
      attributes: true,
      attributeFilter: ["aria-expanded"],
      subtree: true,
    });

    this.behavior = behaviorFromValue(this, this.behaviorValue);
  }

  resetSelectionTargetConnected() {
    this.resetSelection();
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  nodeTargetConnected(element) {
    if (this.isNodeSelected(element)) {
      this.persistSelectNode(element);
    }
  }

  /**
   * actions
   */

  async toggleNodeSelection(event) {
    const toggle = event.currentTarget;
    const nodeRoot = this.getTreeNodeRootForElement(toggle);

    this.toggleNodeSelected(nodeRoot);
  }

  async toggleNode(event) {
    const toggle = event.currentTarget;
    const treeNodeRoot = this.getTreeNodeRootForElement(toggle);
    const hexId = treeNodeRoot.getAttribute("data-entity-hex-id");
    const level = treeNodeRoot.getAttribute("data-tree-level");
    const isSelected = this.isNodeSelected(treeNodeRoot);

    if (!hexId) {
      Flash.addFlash("Sorry, we weren't able to expand that.");
      return;
    }

    let apiRequestParams = {};
    apiRequestParams.hexId = hexId;
    apiRequestParams.level = level;
    apiRequestParams.parentIsSelected = isSelected;
    this.behavior.injectToggleNodeParams(apiRequestParams);

    // fetch treeable children
    const treeableResponse = await Api.Treeables.getChildren(apiRequestParams);

    if (treeableResponse.success) {
      Turbo.renderStreamMessage(treeableResponse.data.html);
      toggle.setAttribute("data-loaded", "true");
    } else {
      Flash.addFlash("Sorry, we weren't able get the data from the server.");
    }
  }

  resetSelection() {
    this.clearSelection();
  }

  /**
   * helpers
   */

  nodeChildrenAreAlreadyLoaded(element) {
    return element.getAttribute("data-loaded") == "true";
  }

  getTreeNodeRootForElement(element) {
    const treeNodeRootId = element.getAttribute("data-tree-node-root-id");
    return this.element.querySelector(`#${treeNodeRootId}`);
  }

  toggleNodeSelected(rootElement) {
    this.behavior.toggleNode(rootElement);
  }

  selectNode(rootElement, options = {}) {
    const { selectChildren = true, handleParents = true } = options;

    this.persistSelectNode(rootElement);

    // ensure non-selected parents are partially selected
    const parent = this.getParentFor(rootElement);

    if (parent && handleParents) {
      const noNonSelectedSibs =
        this.doesNotHaveNonSelectedSiblings(rootElement);

      if (noNonSelectedSibs) {
        parent.setAttribute("data-partially-selected", false);
        this.selectNode(parent, { selectChildren: false });
      } else {
        this.partiallySelectNode(parent);
      }
    }

    if (selectChildren) {
      this.getChildrenFor(rootElement).forEach((childNodeRoot) => {
        this.selectNode(childNodeRoot);
      });
    }
  }

  unselectNode(rootElement) {
    this.persistUnselectNode(rootElement);

    // unselect all children
    this.getChildrenFor(rootElement).forEach((childNodeRoot) => {
      this.unselectNode(childNodeRoot);
    });

    /**
     * If has selected or partially selected siblings
     * - set parent to partially selected
     *
     * If has no selected or part selected siblings
     * - set parent to unselected & un partially selected
     * - repeat for parent's siblings
     */

    const parent = this.getParentFor(rootElement);

    if (parent) {
      const hasSelectedSibs =
        this.hasSelectedOrPartiallySelectedSiblings(rootElement);

      if (this.isNodeSelected(parent) && hasSelectedSibs) {
        this.transitionFromSelectedToPartiallySelected(parent);
      } else if (!hasSelectedSibs) {
        this.unpartiallySelectNode(parent);
      }
    }

    // If this is the last sibling being unselected, make sure the parent is no longer partially selected
  }

  partiallySelectNode(rootElement) {
    rootElement.setAttribute("data-partially-selected", "true");

    const parent = this.getParentFor(rootElement);

    if (parent && !this.isNodeSelected(rootElement))
      this.partiallySelectNode(parent);
  }

  unpartiallySelectNode(rootElement) {
    rootElement.setAttribute("data-partially-selected", "false");

    const parent = this.getParentFor(rootElement);

    if (parent && !this.hasSelectedOrPartiallySelectedSiblings(rootElement))
      this.unpartiallySelectNode(parent);
  }

  transitionFromSelectedToPartiallySelected(rootElement) {
    this.persistUnselectNode(rootElement);
    this.partiallySelectNode(rootElement);

    const parent = this.getParentFor(rootElement);

    if (parent && this.isNodeSelected(parent)) {
      this.transitionFromSelectedToPartiallySelected(parent);
    }
  }

  getParentNodeRootElementsFor(rootElement) {
    let parents = [];
    let parent = rootElement.parentElement;

    while (parent) {
      const isTreeRoot = parent.getAttribute("data-controller") == "tree";

      if (isTreeRoot) {
        // If we're at the tree root then we've found all parents
        return parents;
      }

      if (parent.getAttribute("role") == "treeitem") {
        parents.push(parent);
      }

      parent = parent.parentElement;
    }

    return parents;
  }

  getChildrenFor(rootElement) {
    const nodeList =
      rootElement.querySelectorAll(
        ".hs-accordion-content > .hs-accordion-group > [role='treeitem']"
      ) || [];

    return [...nodeList];
  }

  hasSelectedChildren(rootElement) {
    return (
      this.getChildrenFor(rootElement).filter((c) => this.isNodeSelected(c))
        .length > 0
    );
  }

  getParentFor(rootElement) {
    let parent = rootElement.parentElement;

    while (parent) {
      const isTreeRoot = parent.getAttribute("data-controller") == "tree";

      if (isTreeRoot) {
        // If we're at the tree root then there is no parent
        return null;
      }

      if (parent.getAttribute("role") == "treeitem") {
        return parent;
      }

      parent = parent.parentElement;
    }

    return null;
  }

  getSiblingsFor(rootElement) {
    const parent = this.getParentFor(rootElement);

    if (parent) {
      return this.getChildrenFor(parent)?.filter((c) => c != rootElement) || [];
    } else {
      return [];
    }
  }

  hasSelectedOrPartiallySelectedSiblings(rootElement) {
    const siblings = this.getSiblingsFor(rootElement);

    if (siblings && siblings.length > 0) {
      return (
        siblings.filter(
          (s) => this.isNodeSelected(s) || this.isNodePartiallySelected(s)
        ).length > 0
      );
    }
  }

  doesNotHaveNonSelectedSiblings(rootElement) {
    const siblings = this.getSiblingsFor(rootElement);

    if (siblings && siblings.length > 0) {
      return siblings.filter((s) => !this.isNodeSelected(s)).length == 0;
    }
  }

  isNodeSelected(rootElement) {
    return rootElement.getAttribute("aria-selected") == "true";
  }

  isNodeExpanded(toggleElement) {
    return toggleElement.getAttribute("aria-expanded") == "true";
  }

  isNodePartiallySelected(rootElement) {
    return rootElement.getAttribute("data-partially-selected") == "true";
  }

  persistSelectNode(rootElement) {
    rootElement.setAttribute("aria-selected", "true");
    this.addNodeValueToList(rootElement);
  }

  persistUnselectNode(rootElement) {
    rootElement.setAttribute("aria-selected", "false");
    this.removeNodeValueFromList(rootElement);
  }

  addNodeValueToList(rootElement) {
    const value = rootElement.getAttribute("data-entity-hex-id");

    if (!this.selectedValues) {
      this.selectedValues = [];
    }

    const existing = this.selectedValues.find((v) => v == value);

    if (!existing) {
      this.selectedValues.push(value);
      this.renderActionPanel();
    }
  }

  removeNodeValueFromList(rootElement) {
    const value = rootElement.getAttribute("data-entity-hex-id");

    if (!this.selectedValues) {
      this.selectedValues = [];
    }

    const existing = this.selectedValues.find((v) => v == value);

    if (existing) {
      this.selectedValues = this.selectedValues.filter((v) => v != value);
      this.renderActionPanel();
    }
  }

  addNodeValueToExpandedList(rootElement) {
    const value = rootElement.getAttribute("data-entity-hex-id");

    if (!this.expandedValues) {
      this.expandedValues = [];
    }

    const existing = this.expandedValues.find((v) => v == value);

    if (!existing) {
      this.expandedValues.push(value);
      this.renderActionPanel();
    }
  }

  removeNodeValueFromExpandedList(rootElement) {
    const value = rootElement.getAttribute("data-entity-hex-id");

    if (!this.expandedValues) {
      this.expandedValues = [];
    }

    const existing = this.expandedValues.find((v) => v == value);

    if (existing) {
      this.expandedValues = this.expandedValues.filter((v) => v != value);
      this.renderActionPanel();
    }
  }

  renderActionPanel() {
    const shouldShowActionPanel =
      this.selectedValues && this.selectedValues.length > 0;

    if (this.hasValuesFormFieldTarget) {
      const serializedValues = (this.selectedValues || []).join(",");
      this.valuesFormFieldTarget.value = serializedValues;
    }

    if (this.hasExpandedValuesFormFieldTarget) {
      const serializedValues = (this.expandedValues || []).join(",");
      this.expandedValuesFormFieldTarget.value = serializedValues;
    }

    if (this.hasActionPanelTarget) {
      this.actionPanelTarget.classList.toggle(
        "translate-y-32",
        !shouldShowActionPanel
      );
    }
  }

  clearSelection() {
    this.element
      .querySelectorAll("[aria-selected='true']")
      .forEach((selectedElement) => {
        selectedElement.setAttribute("aria-selected", "false");
      });

    this.element
      .querySelectorAll("[data-partially-selected='true']")
      .forEach((partiallySelectedElement) => {
        partiallySelectedElement.setAttribute(
          "data-partially-selected",
          "false"
        );
      });

    this.selectedValues = [];

    this.renderActionPanel();
  }
}
