import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.observer = new MutationObserver(() => {
      this.scrollToBottom();
    });

    this.observer.observe(this.element, { childList: true });
    this.scrollToBottom();
  }

  disconnect() {
    this.observer.disconnect();
  }

  scrollToBottom() {
    this.element.scroll({ top: this.element.scrollHeight, behavior: "smooth" });
  }
}
