import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    try {
      new window.HSAccordion(this.element);
    } catch {
      console.log(
        "trouble connecting accordion controller. probably initial page load."
      );
    }
  }
}
