import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["row", "actionPanel"];

  /**
   * lifecycle
   */

  actionPanelTargetConnected(actionPanelElement) {
    actionPanelElement.classList.toggle("hidden", false);
    actionPanelElement.classList.toggle("pointer-events-none", true);
    actionPanelElement.classList.toggle("opacity-20", true);
  }

  /**
   * actions
   */

  navigateToRowTarget(event) {
    const rowElement = event.currentTarget;
    const href = rowElement.getAttribute("data-href");

    /**
     * We only want to navigate if we clicked an element that isn't something
     * like a dropdown or other link.
     */
    const shouldPreventNavigation = event
      .composedPath()
      .some(
        (el) =>
          el instanceof HTMLElement &&
          el.getAttribute("data-prevent-navigation") === "true"
      );

    if (!shouldPreventNavigation) {
      Turbo.visit(href, { action: "advance" });
    }
  }

  toggleAll(event) {
    const toggleButton = event.currentTarget;
    const currentlyChecked = toggleButton.checked;

    if (currentlyChecked) {
      this.selectAllRows();
    } else {
      this.unselectAllRows();
    }
  }

  toggleRow(event) {
    const toggle = event.currentTarget;
    const currentlyChecked = toggle.checked;
    const rowRoot = this.getTableRowRootForToggle(toggle);
    const value = rowRoot.getAttribute("data-value");

    if (currentlyChecked) {
      this.addToSelectedValues(value);
    } else {
      this.removeFromSelectedValues(value);
    }

    this.renderActionPanel();
  }

  /**
   * helpers
   */

  unselectAllRows() {
    if (this.hasRowTarget) {
      this.rowTargets.forEach((row) => {
        const rowToggle = this.getToggleForRow(row);
        rowToggle.checked = false;
      });
    }

    this.selectedValues = [];

    this.renderActionPanel();
  }

  selectAllRows() {
    this.selectedValues = [];

    if (this.hasRowTarget) {
      this.rowTargets.forEach((row) => {
        const rowToggle = this.getToggleForRow(row);
        rowToggle.checked = true;
        this.addToSelectedValues(this.getValueForRow(row));
      });
    }

    this.renderActionPanel();
  }

  getToggleForRow(row) {
    return row.querySelector("[data-table-select-row=true]");
  }

  getValueForRow(row) {
    return row.getAttribute("data-value");
  }

  renderActionPanel() {
    if (this.hasSelectedValues()) {
      this.enableActionPanel();
    } else {
      this.disableActionPanel();
    }
  }

  addToSelectedValues(value) {
    if (!this.selectedValues) {
      this.selectedValues = [];
    }

    if (!this.selectedValues.includes(value)) {
      this.selectedValues.push(value);
    }
  }

  removeFromSelectedValues(value) {
    if (!this.selectedValues) {
      this.selectedValues = [];
    }

    if (this.selectedValues.includes(value)) {
      this.selectedValues = this.selectedValues.filter((v) => v != value);
    }
  }

  hasSelectedValues() {
    return this.selectedValues && this.selectedValues.length > 0;
  }

  enableActionPanel() {
    console.dir(this.hasActionPanelTarget);

    if (this.hasActionPanelTarget) {
      this.actionPanelTarget.classList.toggle("pointer-events-none", false);
      this.actionPanelTarget.classList.toggle("opacity-20", false);
    }
  }

  disableActionPanel() {
    if (this.hasActionPanelTarget) {
      this.actionPanelTarget.classList.toggle("pointer-events-none", true);
      this.actionPanelTarget.classList.toggle("opacity-20", true);
    }
  }

  getTableRowRootForToggle(toggle) {
    const tableRowRootId = toggle.getAttribute("data-table-row-id");
    return this.element.querySelector(`#${tableRowRootId}`);
  }
}
